import { AudioChannel } from './Organization/types'

const flexfoneFtpDeleter: IntegrationSchema = {
  color: '#2d94f3',
  name: 'Flexfone FTP deleter',
  description:
    'This will delete all non whitelisted files from the ftp server, tread carefully!',
  auth: [
    { key: 'protocol', label: 'protocol (ftp/sftp)' },
    { key: 'ftpUsername', label: 'ftp Username' },
    { key: 'ftpPassword', label: 'ftp Password' },
    { key: 'ftpHost', label: 'ftp host/url' },
    { key: 'ftpFolder', label: 'ftp path/folder' },
  ],
  hideStartDate: true,
  hideConversationOptions: true,
  audioChannel: { hidden: true, defaultValue: '' },
  integrationOptions: [
    {
      label: 'Whitelisted Agent Numbers',
      key: 'whitelistedAgentNumbers',
      type: 'list',
    },
    {
      label: 'Blacklisted Agent Numbers',
      key: 'blacklistedAgentNumbers',
      type: 'list',
    },
  ],
}

const flexfone: IntegrationSchema = {
  color: '#2d94f3',
  name: 'Flexfone',
  description:
    "**Use teams from dialer DOESN'T work it adds all users to the team set in External Team Uid For All Users**",
  auth: [
    { key: 'protocol', label: 'protocol (ftp/sftp)' },
    { key: 'ftpUsername', label: 'ftp Username' },
    { key: 'ftpPassword', label: 'ftp Password' },
    { key: 'ftpHost', label: 'ftp host/url' },
    { key: 'ftpFolder', label: 'ftp path/folder' },
    { key: 'account', label: 'Flexfone account key' },
    { key: 'hash', label: 'Flexfone hash' },
  ],
  config: {
    showUseTeamFromDialer: true,
  },
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  hideStartDate: false,
  integrationOptions: [
    {
      label: 'Call Direction (outgoing | ingoing | both)',
      key: 'callDirection',
      defaultValue: 'both',
      type: 'text',
    },
    {
      label: 'Conversation title (bnumber | queue | flexfone)',
      key: 'conversationTitle',
      defaultValue: 'bnumber',
      type: 'text',
    },
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },

    {
      label: 'Blacklisted Agent Numbers',
      key: 'blacklistedAgentNumbers',
      type: 'list',
    },
    {
      label: 'Whitelisted Agent Numbers',
      key: 'whitelistedAgentNumbers',
      type: 'list',
    },
    {
      label: 'Blacklisted employee local numbers',
      key: 'BlacklistedEmployeeLocalNumbers',
      type: 'list',
    },
    {
      label: 'Whitelisted employee local numbers',
      key: 'WhitelistedEmployeeLocalNumbers',
      type: 'list',
    },
    {
      label:
        'Whitelisted Agent Key: firstName-lastName_agentNumber (case insensitive)',
      key: 'whitelistedAgentKeys',
      type: 'list',
    },
    {
      label:
        'Blacklisted Agent Key: firstName-lastName_agentNumber (case insensitive)',
      key: 'blacklistedAgentKeys',
      type: 'list',
    },
    { label: 'Whitelisted Queues', key: 'whitelistedQueues', type: 'list' },
    { label: 'Blacklisted Queues', key: 'blacklistedQueues', type: 'list' },
    {
      label: 'External Team Uid For All Users',
      key: 'ExternalTeamUidForAllUsers',
      type: 'text',
    },
  ],
}

const trioNrgi: IntegrationSchema = {
  type: 'trio-nrgi',
  color: '#006e64',
  name: 'Trio NRGI',
  auth: [
    { key: 'username', label: 'ftp Username' },
    { key: 'password', label: 'ftp Password' },
    { key: 'host', label: 'ftp host/url' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: false,
  },
  hideStartDate: false,
  integrationOptions: [
    {
      label: 'minConversationLength',
      key: 'minConversationLength',
      type: 'number',
    },
    {
      label: 'Blacklisted Agent Numbers',
      key: 'blacklistedAgentNumbers',
      type: 'list',
    },
    {
      label: 'Whitelisted Agent Numbers',
      key: 'whitelistedAgentNumbers',
      type: 'list',
    },
    { label: 'Whitelisted Queues', key: 'whitelistedQueues', type: 'list' },
    { label: 'Blacklisted Queues', key: 'blacklistedQueues', type: 'list' },
  ],
}
const touchKrifa: IntegrationSchema = {
  type: 'touch-krifa',
  color: '#651560',
  name: 'Touch Krifa',
  auth: [
    { key: 'username', label: 'ftp Username' },
    { key: 'password', label: 'ftp Password' },
    { key: 'host', label: 'ftp host/url' },
    { key: 'path', label: 'ftp fold path (empty for root)' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  hideStartDate: false,
  integrationOptions: [
    { label: 'Customer type (ask Simon)', key: 'customer', type: 'text' },
    { label: 'Merge calls', key: 'mergeCalls', type: 'boolean' },
    { label: 'Import delay in minutes', key: 'importDelay', type: 'number' },
    { label: 'Whitelisted Queues', key: 'whitelistedQueues', type: 'list' },
    { label: 'Blacklisted Queues', key: 'blacklistedQueues', type: 'list' },
    {
      label: 'Whitelisted Agent Ids',
      key: 'whitelistedAgentIDs',
      type: 'list',
    },
    {
      label: 'Blacklisted Agent Ids',
      key: 'blacklistedAgentIDs',
      type: 'list',
    },

    {
      label: 'Delete Files On Import',
      key: 'deleteFilesOnImport',
      type: 'boolean',
    },
    {
      label: 'Minimum phone number length, used for filter out intrenal calls',
      key: 'minPhoneNumberLength',
      type: 'number',
    },
  ],
}

const senzum: IntegrationSchema = {
  name: 'senzum',
  color: '#1a8abd',
  auth: [
    { key: 'accessKey', label: 'Access Key' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
    { key: 'customer', label: 'Customer' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  hideStartDate: false,
  integrationOptions: [],
}
const puzzel: IntegrationSchema = {
  name: 'DEPRECATED: Puzzel ',
  color: '#eb1562',
  auth: [
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
    { key: 'path', label: 'FTP path', defaultValue: 'recordings' },
  ],
  audioChannel: {
    defaultValue: '',
  },
  hideStartDate: true,
  integrationOptions: [
    {
      label: 'Whitelisted Campaigns',
      key: 'whiteListedCampaigns',
      type: 'list',
    },
    {
      label: 'Blacklisted Campaigns',
      key: 'blackListedCampaigns',
      type: 'list',
    },
  ],
}

const puzzelV3: IntegrationSchema = {
  name: 'Puzzel V3',
  type: 'puzzel-v3',
  color: '#eb1562',
  auth: [
    { key: 'url', label: 'Database URL (hrd0X.puzzel.com)' },
    { key: 'port', label: 'Database port (4 digits)' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
    { key: 'customerNumber', label: 'CustomerNumber (5-6 digits)' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: false,
  },
  config: { showUseTeamFromDialer: true },
  hideStartDate: false,
  integrationOptions: [
    { key: 'useSFTP', label: 'Use SFTP', type: 'boolean', defaultValue: false },
    {
      label: 'Import delay in minutes (how old the call should be)',
      key: 'importDelayInMinutes',
      type: 'number',
      defaultValue: 0,
    },
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: 'Whitelisted Queues Keys',
      key: 'whitelistedQueues',
      type: 'list',
    },
    {
      label: 'Blacklisted Queues Keys',
      key: 'blacklistedQueues',
      type: 'list',
    },
    {
      label: 'Whitelisted User Group Name',
      key: 'whitelistedUserGroupName',
      type: 'list',
    },
    {
      label: 'Blacklisted User Group Name',
      key: 'blacklistedUserGroupName',
      type: 'list',
    },
    {
      label: 'Whitelisted Agent Id',
      key: 'whitelistedAgentId',
      type: 'list',
    },
    {
      label: 'Blacklisted Agent Id',
      key: 'blacklistedAgentId',
      type: 'list',
    },
    {
      label: 'Blacklisted Customer Number',
      key: 'blacklistedCustomers',
      type: 'list',
    },
    {
      label: 'Blacklisted Customer Number Prefixs',
      key: 'blacklistedCustomerPrefixes',
      type: 'list',
    },
    {
      label: 'Whitelisted Customer Number Prefixs',
      key: 'whitelistedCustomerPrefixes',
      type: 'list',
    },
    {
      key: 'cutOutConsulting',
      label: 'Cut Out Consulting Part of Call',
      type: 'boolean',
    },
    {
      label: 'Cerillion Codes to import',
      key: 'cerillionCodes',
      type: 'list',
    },

    {
      label: 'Custom Prop 3',
      key: 'customProp3',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 4',
      key: 'customProp4',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 6',
      key: 'customProp6',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 7',
      key: 'customProp7',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 8',
      key: 'customProp8',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 9',
      key: 'customProp9',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 10',
      key: 'customProp10',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 3',
      key: 'customNumberProp3',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 4',
      key: 'customNumberProp4',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 5',
      key: 'customNumberProp5',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 6',
      key: 'customNumberProp6',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 7',
      key: 'customNumberProp7',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 8',
      key: 'customNumberProp8',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 9',
      key: 'customNumberProp9',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 10',
      key: 'customNumberProp10',
      type: 'text',
      defaultValue: '',
    },
    {
      label:
        'Call variable whitelist filter (consent=true, status=12, someVal=foo)',
      key: 'whitelistedCallVariableFilter',
      type: 'list',
      defaultValue: '',
    },
    {
      label: 'Add email to external uid',
      key: 'addEmailToExternalUid',
      type: 'boolean',
      defaultValue: false,
    },
  ],
}

const puzzelCallbackSurveySync: IntegrationSchema = {
  type: 'puzzel-callback-survey-sync',
  name: 'Puzzel callback survey sync',
  color: '#eb1562',
  auth: [
    { key: 'url', label: 'Database URL (hrd0X.puzzel.com)' },
    { key: 'port', label: 'Database port (4 digits)' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
    { key: 'customerNumber', label: 'CustomerNumber (5-6 digits)' },
  ],
  audioChannel: {
    defaultValue: 'Stereo',
    hidden: true,
  },
  hideStartDate: false,
  hideConversationOptions: true,
  integrationOptions: [
    {
      label: 'Custom Prop 3',
      key: 'customProp3',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 4',
      key: 'customProp4',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 6',
      key: 'customProp6',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 7',
      key: 'customProp7',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 8',
      key: 'customProp8',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 9',
      key: 'customProp9',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 10',
      key: 'customProp10',
      type: 'text',
      defaultValue: '',
    },

    {
      label: 'Custom Number Prop 4',
      key: 'customNumberProp4',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 6',
      key: 'customNumberProp6',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 7',
      key: 'customNumberProp7',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 8',
      key: 'customNumberProp8',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 9',
      key: 'customNumberProp9',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 10',
      key: 'customNumberProp10',
      type: 'text',
      defaultValue: '',
    },
  ],
  defaultSchedule: '*/7 * * * *',
}

const babelforce: IntegrationSchema = {
  name: 'Babelforce',
  type: 'babelforce',
  color: '#5fc7cc',
  auth: [
    { key: 'accessId', label: 'AccessId' },
    { key: 'accessToken', label: 'AccessToken' },
    { key: 'environment', label: 'Environment (eg coop)' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  hideStartDate: false,
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: "Whitelisted Queues id's",
      key: 'whitelistedQueues',
      type: 'list',
    },
    {
      label: "Blacklisted Queues id's",
      key: 'blacklistedQueues',
      type: 'list',
    },
  ],
}
const sallingGroup: IntegrationSchema = {
  type: 'salling-group',
  name: 'Salling Group',
  description:
    'Stores: bilka, bilkatogo, fotex, 3030, br, flowr, koboghent, wupti, fotexhjem, aftersales, nettoplus, sendhent, lastmile, gdpr',
  color: 'blue',
  auth: [{ key: 'token', label: 'Bearer token' }],
  audioChannel: {
    defaultValue: 'Stereo',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length',
      key: 'minConversationLength',
      type: 'number',
    },
    { label: 'Stores to fetch', key: 'stores', type: 'list' },
  ],
}

const puzzelRawDataSyncSms: IntegrationSchema = {
  type: 'puzzel-raw-data-sync-sms',
  name: 'Puzzel raw data sms survey',
  color: '#eb1562',
  auth: [{ key: 'connectionString', label: 'Connection String' }],
  audioChannel: {
    defaultValue: 'Stereo',
    hidden: true,
  },
  hideStartDate: true,
  hideConversationOptions: true,
  integrationOptions: [],
  defaultSchedule: '0 11,23 * * *',
}

const unexusSurveyData: IntegrationSchema = {
  type: 'unexus-survey-data',
  name: 'Unexus Survey Data',
  color: '#8bc000',
  auth: [
    { key: 'ftpUsername', label: 'ftp Username' },
    { key: 'ftpPassword', label: 'ftp Password' },
    { key: 'ftpHost', label: 'ftp host/url' },
    { key: 'ftpFolder', label: 'ftp path/folder' },
    { key: 'ftpPort', label: 'ftp port' },
  ],
  audioChannel: {
    defaultValue: 'Mono1Speaker',
    hidden: true,
  },
  hideStartDate: true,
  hideConversationOptions: true,
  integrationOptions: [],
  defaultSchedule: '0 3 * * *',
}
const twilio: IntegrationSchema = {
  name: 'Twilio',
  color: 'red',
  auth: [
    { key: 'apiKey', label: 'Api Key' },
    { key: 'accountKey', label: 'Account Key' },
  ],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [],
}
const leaddesk: IntegrationSchema = {
  name: 'LeadDesk',
  color: '#432eea',
  auth: [{ key: 'connectionString', label: 'Connection String' }],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [
    {
      label: 'Whitelisted Campaigns Name (case sensitive)',
      key: 'whiteListedCampaigns',
      type: 'list',
    },
    {
      label: 'Blacklisted Campaigns Name (case sensitive)',
      key: 'blackListedCampaigns',
      type: 'list',
    },
    {
      label: 'Min Conversation Length',
      key: 'minConversationLength',
      type: 'number',
    },
  ],
}

const adversusV2: IntegrationSchemaV2 = {
  name: 'adversus V2',
  color: '#3639a4',
  type: 'adversus',
  v2: true,
}
const demoV3: IntegrationSchemaV2 = {
  name: 'Demo V3',
  color: '#216D97',
  type: 'demoV3',
  v2: true,
}
const adversus: IntegrationSchema = {
  name: 'adversus',
  color: '#3639a4',
  auth: [{ label: 'Basic auth (username:password)', key: 'apiKey' }],
  audioChannel: {
    defaultValue: 'Stereo',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'minConversationLength',
      key: 'minConversationLength',
      type: 'number',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },
    { label: 'Whitelisted Users', key: 'whiteListedUsers', type: 'list' },
    { label: 'Blacklisted Users', key: 'blackListedUsers', type: 'list' },
    {
      label: 'Whitelisted Campaigns',
      key: 'whiteListedCampaigns',
      type: 'list',
    },
    {
      label: 'Blacklisted Campaigns',
      key: 'blackListedCampaigns',
      type: 'list',
    },
    {
      label: "Don't include sales",
      key: 'dontIncludeSales',
      type: 'boolean',
    },
  ],
}
const demoAdversus: IntegrationSchema = {
  type: 'demo-adversus',
  name: 'adversus Demo',
  color: '#3639a4',
  auth: [{ label: 'Basic auth (username:password)', key: 'apiKey' }],
  audioChannel: {
    defaultValue: 'Stereo',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'minConversationLength',
      key: 'minConversationLength',
      type: 'number',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },
    { label: 'Whitelisted Users', key: 'whiteListedUsers', type: 'list' },
    { label: 'Blacklisted Users', key: 'blackListedUsers', type: 'list' },
    {
      label: 'Whitelisted Campaigns',
      key: 'whiteListedCampaigns',
      type: 'list',
    },
    {
      label: 'Blacklisted Campaigns',
      key: 'blackListedCampaigns',
      type: 'list',
    },
  ],
}

const telemagic: IntegrationSchema = {
  name: 'Telemagic',
  color: 'purple',
  auth: [{ label: 'Basic auth (username:password)', key: 'apiKey' }],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [
    {
      label:
        'Subdomain (the part before ".telemagic.no", eg: salescorpdk or abcforsikring )',
      key: 'subdomain',
      type: 'text',
    },
    {
      label: 'White Listed Campaigns',
      key: 'whiteListedCampaigns',
      type: 'list',
    },
  ],
}
const aircall: IntegrationSchema = {
  name: 'aircall',
  color: '#00b388',
  auth: [{ label: 'Basic auth (username:password)', key: 'apiKey' }],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [
    {
      label: 'minConversationLength',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whitelistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blacklistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Call Country (dk | se | no | ..)',
      key: 'country',
      type: 'text',
    },
    {
      label: 'Delete recording from aircall',
      key: 'deleteRecordingFromAircall',
      type: 'boolean',
    },
  ],
}
const dixa: IntegrationSchema = {
  name: 'dixa - Dont user, DEPRECATED',
  color: 'purple',
  auth: [{ label: 'Api key', key: 'apiKey' }],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'minConversationLength',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 60,
    },
    {
      label: 'Whitelisted Phone numbers (Full numbers)',
      key: 'whitelistedNumbers',
      type: 'list',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whitelistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blacklistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Use Email As External UserUid (dont enable!)',
      key: 'useEmailAsExternalUserUid',
      type: 'boolean',
      defaultValue: false,
    },
  ],
}

const herobase: IntegrationSchema = {
  color: '#39006c',
  name: 'Herobase / enreach',
  description:
    'leadStatus, preCallLeadStatus, project, leadClosure can all be added as custom props',
  auth: [
    { key: 'apiKey', label: 'apiKey (username:password)' },
    { key: 'server', label: 'server (hero0X)' },
    { key: 'orgCode', label: 'Organization code' },
    { key: 'accessKeyId', label: 'Access Key Id' },
    { key: 'secretAccessKey', label: 'Secret Access Key' },
    { key: 'bucketName', label: 'Bucket Name' },
  ],
  audioChannel: {
    defaultValue: 'Mono1Speaker',
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
    },
    {
      label: 'Org codes',
      key: 'orgCodes',
      type: 'list',
    },
    {
      label: 'Import Partial Recordings',
      key: 'importPartialRecordings',
      type: 'boolean',
    },
    {
      label: 'Whitelisted Campaigns (uniqueId)',
      key: 'whiteListedCampaigns',
      type: 'list',
    },
    {
      label: 'Blacklisted Campaigns (uniqueId)',
      key: 'blackListedCampaigns',
      type: 'list',
    },
    {
      label: 'Whitelisted username',
      key: 'whitelistedUsernames',
      type: 'list',
    },
    {
      label: 'Blacklisted username',
      key: 'blacklistedUsernames',
      type: 'list',
    },
    {
      label: 'Whitelisted projects',
      key: 'whitelistedProjects',
      type: 'list',
    },
    {
      label: 'Blacklisted projects',
      key: 'blacklistedProjects',
      type: 'list',
    },
    {
      label: 'Whitelisted Unique User Id',
      key: 'whitelistedUniqueUserId',
      type: 'list',
    },
    {
      label: 'Blacklisted Unique User Id',
      key: 'blacklistedUniqueUserId',
      type: 'list',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Custom labels',
      key: 'customLabels',
      type: 'list',
    },
    {
      label: "Use legacyLabels labels (don't)",
      key: 'legacyLabels',
      type: 'boolean',
    },
    {
      label: 'Custom Prop 1',
      key: 'customProp1',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 2 *Defaults to orgCode*',
      key: 'customProp2',
      type: 'text',
      defaultValue: '',
    },

    {
      label: 'Custom Prop 3 *defaults to Campaign Project*',
      key: 'customProp3',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 4 *defaults to uniqueLeadId',
      key: 'customProp4',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 5',
      key: 'customProp5',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 6',
      key: 'customProp6',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 7',
      key: 'customProp7',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 8',
      key: 'customProp8',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 9',
      key: 'customProp9',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 10',
      key: 'customProp10',
      type: 'text',
      defaultValue: '',
    },
    {
      label: "Agent only campaign id's (overrides default)",
      key: 'oneSpeakerCampaignUids',
      type: 'list',
    },
    {
      label: "Agent and customer campaign id's (overrides default)",
      key: 'twoSpeakerCampaignUids',
      type: 'list',
    },

    {
      label: 'Days to look ahead',
      defaultValue: 10,
      key: 'daysToLookAhead',
      type: 'number',
    },
    {
      label: 'Import delay in minutes (how old the call should be)',
      key: 'importDelayInMinutes',
      type: 'number',
      defaultValue: 0,
    },
  ],
  defaultSchedule: '*/30 * * *',
}

const unexus: IntegrationSchema = {
  color: '#8bc000',
  name: 'Unexus',
  auth: [
    { key: 'ftpUsername', label: 'ftp Username' },
    { key: 'ftpPassword', label: 'ftp Password' },
    { key: 'ftpHost', label: 'ftp host/url' },
    { key: 'ftpFolder', label: 'ftp path/folder' },
    { key: 'ftpPort', label: 'ftp port' },
    { key: 'apiUsername', label: 'api Username' },
    { key: 'apiPassword', label: 'api Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: false,
  },
  integrationOptions: [
    {
      label: 'minConversationLength',
      key: 'minConversationLength',
      type: 'number',
    },
    { label: 'Whitelisted Queues', key: 'whitelistedQueues', type: 'list' },
    { label: 'Blacklisted Queues', key: 'blacklistedQueues', type: 'list' },
    {
      label: 'Delete files older than 30 days',
      key: 'deleteFilesOlderThan30Days',
      type: 'boolean',
    },
  ],
}

const teliaNice: IntegrationSchema = {
  color: '#990ae3',
  name: 'Telia Nice',
  type: 'telia-nice',
  auth: [
    { key: 'addr', label: 'SFTP address (sftp.ccs.teliacompany.net:22)' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 0,
    },
    { label: 'Whitelisted Queues', key: 'whitelistedQueues', type: 'list' },
    { label: 'Blacklisted Queues', key: 'blacklistedQueues', type: 'list' },
    {
      label: 'Whitelisted agent login name',
      key: 'whitelistedAgentLoginName',
      type: 'list',
    },
    {
      label: 'Blacklisted agent login name',
      key: 'blacklistedAgentLoginName',
      type: 'list',
    },
  ],
}
const netsS3: IntegrationSchema = {
  color: '#36ACD7FF',
  name: 'Nets s3',
  type: 'nets-s3',
  auth: [
    { key: 'region', label: 'AWS Region' },
    { key: 'Bucket', label: 'S3 Bucket' },
    { key: 'AccessKeyId', label: 'AccessKeyId' },
    { key: 'SecretAccessKey', label: 'SecretAccessKey' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    values: ['Mono1Speaker', 'Mono2Speaker'],
    disable: false,
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 0,
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'WhitelistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'BlacklistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Whitelisted Agent',
      key: 'WhitelistedAgent',
      type: 'list',
    },
    {
      label: 'Blacklisted Agent',
      key: 'BlacklistedAgent',
      type: 'list',
    },
  ],
}

const ciscoS3: IntegrationSchema = {
  type: 'cisco-s3',

  color: 'green',
  name: 'Cisco s3',
  description:
    "Hvis du har brug for at starte efter en specifik dato, så få en udvikler til at finde key'en i s3 og sæt den ind i continuousToken",
  auth: [
    { key: 'bucket', label: 'S3 bucket' },
    { key: 'accessKeyID', label: 'AWS Access Key ID' },
    { key: 'secretAccessKey', label: 'AWS Secret Access Key' },
  ],
  audioChannel: {
    defaultValue: 'Stereo',
    disable: true,
  },
  hideStartDate: true,
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: 'XML parser, dlg or empty (Ask SIK)',
      key: 'parserType',
      type: 'text',
      defaultValue: '',
    },
  ],
}
const miralix: IntegrationSchema = {
  color: '#53ba00',
  name: 'Miralix',
  description: '',
  auth: [{ key: 'apiKey', label: 'Api nøgle' }],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },

  integrationOptions: [
    {
      label: 'Api url (.../mot/greenbox/)',
      key: 'url',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: 'Køer, der skal hentes ind fra (nummer)',
      key: 'queues',
      type: 'list',
      defaultValue: '',
    },
    {
      label: 'Undlad at konvertere til mono',
      key: 'dontConvertToMono',
      type: 'boolean',
      defaultValue: false,
    },
  ],
}
const ipvision: IntegrationSchema = {
  description: 'Det er en webscraper integrations',
  color: '#15374f',
  name: 'IpVision - web scraper',
  auth: [
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
    { key: 'customerRef', label: 'Customer Ref' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: 'Conversation Title',
      key: 'conversationTitle',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Use DDI As Title',
      key: 'useDdiAsTitle',
      type: 'boolean',
      defaultValue: false,
    },
    {
      label: 'Department Whitelist (Case Sensitive)',
      key: 'departmentWhitelist',
      type: 'list',
    },
    {
      label: 'Whitelisted User id/extension',
      key: 'whitelistedUserUids',
      type: 'list',
    },
    {
      label: 'Blacklisted Users id/extension',
      key: 'blacklistedUserUids',
      type: 'list',
    },
    {
      label: 'Whitelisted DDI (key or name)',
      key: 'whitelistedDdi',
      type: 'list',
    },
    {
      label: 'Blacklisted DDI (key or name)',
      key: 'blacklistedDdi',
      type: 'list',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Minutes To Look Ahead',
      key: 'minutesToLookAhead',
      type: 'number',
      defaultValue: 60,
    },
    {
      label: 'Call Direction (outgoing | incoming)',
      key: 'direction',
      defaultValue: '',
      type: 'text',
    },
  ],
  defaultSchedule: '*/10 * * *',
}
const ipvisionApi: IntegrationSchema = {
  type: 'ipvison-api',
  color: '#15374f',
  name: 'IpVision API',
  auth: [
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 10,
    },
    {
      label: 'Conversation Title',
      key: 'conversationTitle',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Use DDI As Title',
      key: 'useDdiAsTitle',
      type: 'boolean',
      defaultValue: false,
    },

    {
      label: 'DDI map',
      key: 'ddimap',
      type: 'list',
    },
    {
      label: 'Department Whitelist (Case Sensitive)',
      key: 'departmentWhitelist',
      type: 'list',
    },
    {
      label: 'Whitelisted User id/extension',
      key: 'whitelistedUserUids',
      type: 'list',
    },
    {
      label: 'Blacklisted Users id/extension',
      key: 'blacklistedUserUids',
      type: 'list',
    },
    {
      label: 'Whitelisted DDI',
      key: 'whitelistedDdi',
      type: 'list',
    },
    {
      label: 'Blacklisted DDI',
      key: 'blacklistedDdi',
      type: 'list',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Minutes To Look Ahead',
      key: 'minutesToLookAhead',
      type: 'number',
      defaultValue: 60,
    },
    {
      label: 'Call Direction ("O,I" | "O" | "I")',
      key: 'direction',
      defaultValue: '',
      type: 'text',
    },
    {
      label: 'Whitelisted Extensions',
      key: 'whitelistedExtensions',
      type: 'list',
    },
  ],
  defaultSchedule: '*/10 * * *',
}

const lunar: IntegrationSchema = {
  name: 'Lunar',
  color: '#1e1e1e',
  auth: [
    { label: 'lunarApiKey', key: 'lunarApiKey' },
    { label: 'Twilio Api Key', key: 'twilioApiKey' },
    {
      key: 'Api URL',
      label: 'apiUrl',
      defaultValue: 'https://api.prod.lunarway.com/capturi/',
    },
  ],
  audioChannel: {
    defaultValue: 'Stereo',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Language (danish)',
      key: 'language',
      type: 'text',
    },
  ],
}

const recordit: IntegrationSchema = {
  name: 'recordit / touch call',
  color: '#0e7f35',
  description: '',
  auth: [
    { key: 'ftpUsername', label: 'ftp Username' },
    { key: 'ftpPassword', label: 'ftp Password' },
    { key: 'ftpHost', label: 'ftp host/url' },
    { key: 'ftpPort', label: 'ftp port' },
    { key: 'path', label: 'ftp path/folder' },
  ],
  hideStartDate: true,
  audioChannel: { defaultValue: 'Mono2Speaker' },
  integrationOptions: [
    {
      label: 'Whitelisted Agent Names (case insensitive)',
      key: 'whiteListedAgents',
      type: 'list',
    },
    {
      label: 'Blacklisted Agent Names (case insensitive)',
      key: 'blackListedAgents',
      type: 'list',
    },
    {
      label: 'Delete Old Conversations From Ftp',
      key: 'deleteOldConversationsFromFtp',
      type: 'boolean',
    },
  ],
}
const zendesk: IntegrationSchema = {
  name: 'zendesk',
  color: '#17494d',
  description:
    'Email should be the email of the person who created the api token. Subdomain is <subdomain>.zendesk.com',
  auth: [
    { key: 'email', label: 'Email' },
    { key: 'token', label: 'Token' },
    { key: 'subdomain', label: 'Subdomain' },
  ],
  audioChannel: { defaultValue: 'Mono2Speaker', disable: true },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label:
        'Call Direction (inbound | outbound) - leave empty for both directions',
      key: 'CallDirection',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Conversation Title',
      key: 'ConversationTitle',
      type: 'text',
      defaultValue: '',
    },
    {
      label: "Whitelisted Agent id's",
      key: 'whitelistedAgents',
      type: 'list',
    },
    {
      label: "Blacklisted Agent id's",
      key: 'blacklistedAgents',
      type: 'list',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whitelistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blacklistedNumberPrefixes',
      type: 'list',
    },
  ],
}
const zendeskPuzzelCRM: IntegrationSchema = {
  type: 'zendesk-puzzel-crm',
  name: 'Zendesk Puzzel CRM',
  color: '#17494d',
  description:
    'Email should be the email of the person who created the api token. Subdomain is <subdomain>.zendesk.com, can use both zendeskId or phone number and date to sync',
  auth: [
    { key: 'email', label: 'Email' },
    { key: 'token', label: 'Token' },
    { key: 'subdomain', label: 'Subdomain' },
  ],
  audioChannel: { hidden: true, defaultValue: 'Stereo' },
  hideConversationOptions: true,
  defaultSchedule: '*/30 * * * *',
  integrationOptions: [
    {
      label: 'Capturi Integration Key',
      key: 'CapturiIntegrationKey',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'User Zendesk to sync',
      key: 'UseZendeskTicketIdMatch',
      type: 'boolean',
      defaultValue: false,
    },
    {
      label: 'Zendesk TicketId CustomProp Field (1-10)',
      key: 'ZendeskTicketIdCustomPropField',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'User date and phone number to sync',
      key: 'useFuzzyMatch',
      type: 'boolean',
      defaultValue: false,
    },
    {
      label: 'Max minutes in the future',
      key: 'maxMinutesInTheFuture',
      type: 'number',
      defaultValue: 10,
    },
    {
      label: 'Customer phone number field',
      key: 'customerPhoneNumberField',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Puzzel Agent id field',
      key: 'puzzelAgentIdField',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 1',
      key: 'customProp1',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 2',
      key: 'customProp2',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 3',
      key: 'customProp3',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 4',
      key: 'customProp4',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 5',
      key: 'customProp5',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 6',
      key: 'customProp6',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 7',
      key: 'customProp7',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 8',
      key: 'customProp8',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 9',
      key: 'customProp9',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Prop 10',
      key: 'customProp10',
      type: 'text',
      defaultValue: '',
    },

    {
      label: 'Custom Number Prop 1',
      key: 'custoNumbermProp1',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 2',
      key: 'custoNumbermProp2',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 3',
      key: 'custoNumbermProp3',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 4',
      key: 'custoNumbermProp4',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 5',
      key: 'custoNumbermProp5',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 6',
      key: 'custoNumbermProp6',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 7',
      key: 'custoNumbermProp7',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 8',
      key: 'custoNumbermProp8',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 9',
      key: 'custoNumbermProp9',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Custom Number Prop 10',
      key: 'customNumberProp10',
      type: 'text',
      defaultValue: '',
    },
  ],
}

const jyskFynskeMedier: IntegrationSchema = {
  type: 'jysk-fynske-medier',
  color: '#95c11e',
  name: 'Jysk Fynske Medier',
  auth: [
    { key: 'protocol', label: 'protocol (ftp/sftp)' },
    { key: 'ftpUsername', label: 'ftp Username' },
    { key: 'ftpPassword', label: 'ftp Password' },
    { key: 'ftpHost', label: 'ftp host/url' },
    { key: 'ftpPort', label: 'ftp port' },
    { key: 'ftpFolder', label: 'ftp path/folder' },
  ],
  audioChannel: { disable: true, defaultValue: 'Mono2Speaker' },
  integrationOptions: [],
}
const jyskFynskeMedierMetadataSyncer: IntegrationSchema = {
  type: 'jysk-fynske-medier-metadata-syncer',
  color: '#95c11e',
  name: 'Jysk Fynske Medier Metadata Syncer',
  auth: [],
  audioChannel: { disable: true, defaultValue: 'Mono2Speaker' },
  integrationOptions: [],
}
const jfmRetention: IntegrationSchema = {
  type: 'jfm-retention',
  color: '#95c11e',
  name: 'Jysk Fynske Medier Retention Syncer',
  auth: [],
  audioChannel: { disable: true, defaultValue: 'Mono2Speaker' },
  integrationOptions: [],
  defaultSchedule: '0 5 * * *',
}
const systemTest: IntegrationSchema = {
  type: 'system-test',
  color: '#266c81',
  name: 'System Test',
  auth: [],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [],
}
const coopS3: IntegrationSchema = {
  color: '#c31414',
  name: 'Coop S3',
  auth: [],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [],
}
const demoOutbound: IntegrationSchema = {
  color: '#266c82',
  name: 'Demo integration',
  description: 'The dataset should first be added to integration-demo-outbound',
  auth: [],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [
    { key: 'dataset', label: 'Dateset', type: 'text' },
    { key: 'subjects', label: 'Subjects', type: 'list' },
    {
      key: 'percentageToCreate',
      label: 'Percentage Of conversations to Create per day',
      type: 'number',
      defaultValue: 100,
    },
  ],
  defaultSchedule: '07,37 8-16 * * 1-5',
}
const simpleS3: IntegrationSchema = {
  color: '#FF9900',
  type: 'simple-s3',
  name: 'Simple S3',
  description:
    'Simple s3 integrations, simply takes the files from S3 and adds them. Using the filename as title and Agent Name as user',
  auth: [
    { key: 'bucket', label: 'Bucket' },
    { key: 'accessKeyID', label: 'AccessKeyID (Optional)' },
    { key: 'secretAccessKey', label: 'SecretAccessKey (Optional)' },
  ],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [
    { key: 'agentName', label: ' Agent Name', type: 'text' },
  ],
}
const anywhere365: IntegrationSchema = {
  color: '#FF7121',
  type: 'anywhere365',
  name: 'anywhere365',
  auth: [
    { key: 'siteURL', label: 'SiteURL' },
    { key: 'ClientID', label: 'ClientID' },
    { key: 'ClientSecret', label: 'ClientSecret' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Conversation title',
      key: 'title',
      type: 'text',
    },
    {
      label: 'Seconds to cut from start of callback calls',
      key: 'SecondsToCutFromCallbacks',
      type: 'number',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },
    { label: 'Import delay in minutes', key: 'importDelay', type: 'number' },
  ],
}
const callMaker: IntegrationSchema = {
  color: '#2cb85a',
  type: 'call-make',
  name: 'CallMaker',
  auth: [
    { key: 'ClientID', label: 'ClientID' },
    { key: 'ClientSecret', label: 'ClientSecret' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: false,
  },
  integrationOptions: [
    {
      label: 'Customer is recording agent and customer on inbound calls',
      key: 'inboundHas2Speakers',
      type: 'boolean',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Whitelisted Workflows',
      key: 'whitelistedWorkflows',
      type: 'list',
    },
    {
      label: 'Blacklisted Workflows',
      key: 'blacklistedWorkflows',
      type: 'list',
    },
  ],
}
const genesysCloud: IntegrationSchema = {
  color: '#FF4F1F',
  type: 'genesys-cloud',
  name: 'Genesys Cloud',
  auth: [
    { key: 'url', label: 'URL', defaultValue: 'https://api.mypurecloud.de' },
    { key: 'clientID', label: 'ClientID' },
    { key: 'clientSecret', label: 'ClientSecret' },
  ],
  audioChannel: {
    defaultValue: 'Stereo',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: 'Whitelisted Queues',
      key: 'whitelistedQueues',
      type: 'list',
    },
    {
      label: 'Blacklisted Queues',
      key: 'blacklistedQueues',
      type: 'list',
    },
    {
      label: 'User cutter v2',
      key: 'useCutterV2',
      type: 'boolean',
      defaultValue: true,
    },
  ],
}
const vonage: IntegrationSchema = {
  color: '#881fff',
  type: 'vonage',
  name: 'Vonage',
  auth: [
    { key: 'clientID', label: 'ClientID' },
    { key: 'clientSecret', label: 'ClientSecret' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 15,
    },
    {
      label: 'Direction [outbound | inbound] - leave empty for both directions',
      key: 'direction',
      type: 'text',
    },
    {
      label: 'Whitelisted Queues',
      key: 'whitelistedQueues',
      type: 'list',
    },
    {
      label: 'Blacklisted Queues',
      key: 'blacklistedQueues',
      type: 'list',
    },
    {
      label: 'Whitelisted Agents',
      key: 'whitelistedAgents',
      type: 'list',
    },
    {
      label: 'Blacklisted Agents',
      key: 'blacklistedAgents',
      type: 'list',
    },
    {
      label: 'Whitelisted Dispositions',
      key: 'whitelistedDispositions',
      type: 'list',
    },
    {
      label: 'Blacklisted Dispositions',
      key: 'blacklistedDispositions',
      type: 'list',
    },
    {
      label: "Don't cut audio",
      key: 'dontCutAudio',
      type: 'boolean',
    },
  ],
}

const vonageMetadataSync: IntegrationSchema = {
  color: '#881fff',
  type: 'vonage-metadata-sync',
  name: 'vonage-metadata-sync',
  auth: [
    { key: 'clientID', label: 'ClientID' },
    { key: 'clientSecret', label: 'ClientSecret' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    hidden: true,
  },
  integrationOptions: [
    {
      label: 'Days to look back',
      key: 'daysToLookBack',
      type: 'number',
    },
  ],
}
const relatel: IntegrationSchema = {
  color: '#005156',
  type: 'relatel',
  name: 'relatel',
  auth: [{ key: 'authToken', label: 'AuthToken' }],
  audioChannel: {
    defaultValue: 'Stereo',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: 'Whitelisted customer number prefix (eg: 45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: 46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },

    {
      label: 'Whitelisted Queues',
      key: 'whitelistedQueues',
      type: 'list',
    },
    {
      label: 'Blacklisted Queues',
      key: 'blacklistedQueues',
      type: 'list',
    },
    {
      label: 'Call Direction (incoming | outgoing | both)',
      key: 'callDirection',
      defaultValue: 'both',
      type: 'text',
    },
  ],
}
const close: IntegrationSchema = {
  color: '#1463ff',
  type: 'close',
  name: 'close',
  auth: [{ key: 'authToken', label: 'AuthToken' }],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    values: ['Mono1Speaker', 'Mono2Speaker'],
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 30,
    },
    {
      label: 'Whitelisted customer number prefix (eg: 45)',
      key: 'whiteListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: 46)',
      key: 'blackListedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Call Direction (inbound | outbound | both)',
      key: 'callDirection',
      defaultValue: 'both',
      type: 'text',
    },
  ],
}
const dubber: IntegrationSchema = {
  color: '#003169',
  type: 'dubber',
  name: 'dubber',
  auth: [
    { key: 'dubberAccountId', label: 'DubberAccountId' },
    { key: 'clientId', label: 'ClientId' },
    { key: 'clientSecret', label: 'ClientSecret' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Stereo',
    disable: true,
  },
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 10,
    },
    {
      label: 'Team Ids',
      key: 'teamIds',
      type: 'list',
    },
    {
      label: 'Whitelisted customer number prefix (eg: 45)',
      key: 'whitelistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: 46)',
      key: 'blacklistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Whitelisted Called Numbers',
      key: 'WhitelistedCalledNumbers',
      type: 'list',
    },
    {
      label: 'Blacklisted Called Numbers',
      key: 'BlacklistedCalledNumbers',
      type: 'list',
    },
    {
      label: 'Delete Recording on Import',
      key: 'DeleteRecordingOnImport',
      type: 'boolean',
    },
    {
      label: 'Use Real Agent Info',
      key: 'useRealAgentInfo',
      type: 'boolean',
    },
  ],
}

const dstnyTollring: IntegrationSchema = {
  color: '#3a5b62',
  type: 'dstny-tollring',
  name: 'dstny Tollring',
  auth: [
    { key: 'username', label: 'Username (email)' },
    { key: 'token', label: 'Token' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
  },
  integrationOptions: [
    {
      key: 'apiEndpoint',
      label:
        'Api URL (eg: https://recording.dstny.se, https://inspelning.tele2.se)',
      type: 'text',
    },
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
    },
    {
      label: 'Whitelisted customer number prefix (eg: +45)',
      key: 'whitelistedNumberPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted customer number prefix (eg: +46)',
      key: 'blacklistedNumberPrefixes',
      type: 'list',
    },

    {
      label: 'Whitelisted Queues',
      key: 'whitelistedQueues',
      type: 'list',
    },
    {
      label: 'Blacklisted Queues',
      key: 'blacklistedQueues',
      type: 'list',
    },
    {
      label: 'Whitelisted Directions (I, Z, Y)',
      key: 'whitelistedDirections',
      type: 'list',
    },
  ],
}

const touchTelavox: IntegrationSchema = {
  name: 'Touch Telavox',
  type: 'touch-telavox',
  color: '#0c7f35',
  auth: [
    { key: 'host', label: 'Host', defaultValue: 'sftp.capturi.com' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
  },
  hideStartDate: false,
  integrationOptions: [
    {
      key: 'ekstraSecondsToCut',
      label:
        'Ekstra seconds to cut, from start of conversations when there is waiting music',
      type: 'number',
    },
    {
      label: 'Whitelisted Agent Phone Number (+4532251036)',
      key: 'whitelistedAgentNumber',
      type: 'list',
    },
    {
      label: 'Blacklisted Agent Phone Number (+4532251054)',
      key: 'blacklistedAgentNumber',
      type: 'list',
    },
    {
      label: 'Whitelisted Queues',
      key: 'whitelistedQueue',
      type: 'list',
    },
    {
      label: 'Blacklisted Queues',
      key: 'blacklistedQueue',
      type: 'list',
    },
  ],
}
const telavoxV2: IntegrationSchema = {
  name: 'Telavox V2',
  type: 'telavox-v2',
  color: '#0c7f35',
  auth: [
    { key: 'host', label: 'Host' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
  },
  hideStartDate: false,
  integrationOptions: [
    {
      label: 'Min Conversation Length (sec)',
      key: 'minConversationLength',
      type: 'number',
      defaultValue: 0,
    },
    {
      label: 'Seconds to cut from the start of conversation',
      key: 'secondsToCut',
      type: 'number',
      defaultValue: 0,
    },
    { label: 'Whitelisted Queues', key: 'whitelistedQueues', type: 'list' },
    { label: 'Blacklisted Queues', key: 'blacklistedQueues', type: 'list' },
    {
      label: 'Whitelisted Customer number prefix',
      key: 'whitelistedCustomerPrefixes',
      type: 'list',
    },
    {
      label: 'Blacklisted Customer number prefix',
      key: 'blacklistedCustomerPrefixes',
      type: 'list',
    },
  ],
}
const connectel: IntegrationSchema = {
  name: 'Connectel',
  type: 'connectel',
  color: '#e7f2f0',
  auth: [
    { key: 'host', label: 'Host' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
  },
  hideStartDate: true,
  integrationOptions: [
    { label: 'Whitelisted Queues', key: 'whitelistedQueues', type: 'list' },
    { label: 'Blacklisted Queues', key: 'blacklistedQueues', type: 'list' },
  ],
}

const jansson: IntegrationSchema = {
  name: 'Jansson',
  type: 'jansson',
  color: '#0c7f35',
  auth: [
    { key: 'host', label: 'Host', defaultValue: 'sftp.capturi.com' },
    { key: 'port', label: 'Port', defaultValue: '22' },
    { key: 'folder', label: 'Folder' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: { disable: true, defaultValue: 'Mono2Speaker' },
  integrationOptions: [
    { label: 'Whitelisted Queues', key: 'whitelistedQueues', type: 'list' },
    { label: 'Blacklisted Queues', key: 'blacklistedQueues', type: 'list' },
  ],
}

const nykredit: IntegrationSchema = {
  name: 'Nykredit',
  color: '#0f1e82',
  auth: [
    { key: 'host', label: 'Host', defaultValue: 'sftp.capturi.com' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  config: {
    showUseTeamFromDialer: true,
  },
  hideStartDate: false,
  integrationOptions: [
    {
      label:
        'Whitelisted Skills (without spaces "VIP Service" -> "VIPService")',
      key: 'whitelistedSkills',
      type: 'list',
    },
    {
      label:
        'Blacklisted Skills (without spaces "VIP Service" -> "VIPService")',
      key: 'blacklistedSkills',
      type: 'list',
    },
    {
      label: 'Whitelisted Segments',
      key: 'whitelistedSegments',
      type: 'list',
    },
    {
      label: 'Blacklisted Segments',
      key: 'BlacklistedSegments',
      type: 'list',
    },
    {
      label: 'Whitelisted Queues',
      key: 'whitelistedQueues',
      type: 'list',
    },
    {
      label: 'Blacklisted Queues',
      key: 'BlacklistedQueues',
      type: 'list',
    },
    {
      label: 'Whitelisted Department',
      key: 'WhitelistedDepartment',
      type: 'list',
    },
    {
      label: 'Blacklisted Department',
      key: 'BlacklistedDepartment',
      type: 'list',
    },
    {
      label: 'Whitelisted Company (Afdeling nummer fra 1-n)',
      key: 'whitelistedCompany',
      type: 'list',
    },
    {
      label: 'Blacklisted Company (Afdeling nummer fra 1-n)',
      key: 'blacklistedCompany',
      type: 'list',
    },
  ],
}
const nykreditV2: IntegrationSchema = {
  type: 'nykredit-v2',
  name: 'Nykredit v2',
  color: '#0f1e82',
  auth: [
    { key: 'host', label: 'Host' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  config: {
    showUseTeamFromDialer: true,
  },
  hideStartDate: false,
  integrationOptions: [
    {
      label: 'Whitelisted Skills',
      key: 'whitelistedSkills',
      type: 'list',
    },
    {
      label: 'Blacklisted Skills',
      key: 'blacklistedSkills',
      type: 'list',
    },
    {
      label: 'Whitelisted Segments',
      key: 'whitelistedSegments',
      type: 'list',
    },
    {
      label: 'Blacklisted Segments',
      key: 'BlacklistedSegments',
      type: 'list',
    },
    {
      label: 'Whitelisted Queues',
      key: 'whitelistedQueues',
      type: 'list',
    },
    {
      label: 'Blacklisted Queues',
      key: 'BlacklistedQueues',
      type: 'list',
    },
    {
      label: 'Whitelisted Department',
      key: 'WhitelistedDepartment',
      type: 'list',
    },
    {
      label: 'Blacklisted Department',
      key: 'BlacklistedDepartment',
      type: 'list',
    },
    {
      label: 'Whitelisted Company (Afdeling nummer fra 1-n)',
      key: 'whitelistedCompany',
      type: 'list',
    },
    {
      label: 'Blacklisted Company (Afdeling nummer fra 1-n)',
      key: 'blacklistedCompany',
      type: 'list',
    },
    {
      label: 'Import Forwards',
      key: 'importForwards',
      type: 'boolean',
    },
  ],
}
const nykreditMetadata: IntegrationSchema = {
  type: 'nykredit-metadata',
  name: 'Nykredit Metadata',
  color: '#0f1e82',
  auth: [
    { key: 'host', label: 'Host including port' },
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: 'Mono2Speaker',
    disable: true,
  },
  config: {
    showUseTeamFromDialer: false,
  },
  hideStartDate: false,
  integrationOptions: [],
}

const empty: IntegrationSchema = {
  color: '#ffffff',
  name: 'empty',
  auth: [
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  audioChannel: {
    defaultValue: '',
  },
  integrationOptions: [],
}
export type AudioChannelOptions =
  | {
      defaultValue: AudioChannel | ''
      values?: AudioChannel[]
      hidden?: boolean
      disable?: false
    }
  | {
      hidden: true
      defaultValue: AudioChannel
      values?: AudioChannel[]
      disable?: boolean
    }
  | {
      values?: AudioChannel[]
      hidden?: false
      disable: true
      defaultValue: AudioChannel
    }

export type IntegrationSchema = {
  type?: string
  name: string
  color: string
  description?: string
  hideStartDate?: boolean
  audioChannel: AudioChannelOptions
  auth: FieldOption[]
  integrationOptions: FieldOption[]
  config?: {
    showUseTeamFromDialer: boolean
  }
  hideConversationOptions?: boolean
  defaultSchedule?: string
  v2?: false
}

export type IntegrationSchemaV2 = {
  type: string
  name: string
  color: string
  description?: string
  v2: true
}

export type FieldOption =
  | {
      label: string
      key: string
      defaultValue?: string | number | boolean
      type?: 'number' | 'text' | 'boolean' | 'select' | 'list'
    }
  | {
      label: string
      key: string
      defaultValue?: boolean
      type?: 'boolean'
    }

export const integrations = new Map<
  string,
  IntegrationSchema | IntegrationSchemaV2
>([
  ['close', close],
  ['adversus', adversus],
  ['adversus-v2', adversusV2],
  ['demo-adversus', demoAdversus],
  ['demoV3', demoV3],
  ['aircall', aircall],
  ['dixa', dixa],
  ['flexfone', flexfone],
  ['herobase', herobase],
  ['leaddesk', leaddesk],
  ['puzzel', puzzel],
  ['puzzel-v3', puzzelV3],
  ['puzzel-raw-data-sync-sms', puzzelRawDataSyncSms],
  ['puzzel-callback-survey-sync', puzzelCallbackSurveySync],
  ['salling-group', sallingGroup],
  ['telemagic', telemagic],
  ['twilio', twilio],
  ['unexus', unexus],
  ['unexus-survey-data', unexusSurveyData],
  ['flexfone-ftp-deleter', flexfoneFtpDeleter],
  ['cisco-s3', ciscoS3],
  ['ipvision', ipvision],
  ['recordit', recordit],
  ['lunar', lunar],
  ['miralix', miralix],
  ['zendesk', zendesk],
  ['zendesk-puzzel-crm', zendeskPuzzelCRM],
  ['system-test', systemTest],
  ['coop-s3', coopS3],
  ['jysk-fynske-medier', jyskFynskeMedier],
  ['jysk-fynske-medier-metadata-syncer', jyskFynskeMedierMetadataSyncer],
  ['demo-outbound', demoOutbound],
  ['simple-s3', simpleS3],
  ['nykredit', nykredit],
  ['nykredit-v2', nykreditV2],
  ['nykredit-metadata', nykreditMetadata],
  ['jfm-retention', jfmRetention],
  ['babelforce', babelforce],
  ['anywhere365', anywhere365],
  ['relatel', relatel],
  ['senzum', senzum],
  ['touch-telavox', touchTelavox],
  ['telavox-v2', telavoxV2],
  ['jansson', jansson],
  ['genesys-cloud', genesysCloud],
  ['vonage', vonage],
  ['vonage-metadata-sync', vonageMetadataSync],
  ['trio-nrgi', trioNrgi],
  ['touch-krifa', touchKrifa],
  ['telia-nice', teliaNice],
  ['nets-s3', netsS3],
  ['connectel', connectel],
  ['dstny-tollring', dstnyTollring],
  ['call-maker', callMaker],
  ['dubber', dubber],
  ['ipvision-api', ipvisionApi],

  ['empty', empty],
])
