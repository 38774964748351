import request from '@capturi/request'
import { Heading, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { ItemCard } from './ItemCard'
type Item = {
  shouldRestart: true
  integrationKey: string
  integrationType: string
  organizationUid: string
  isRunning: boolean
  lastRunStarted: null | Date
  lastRunEnded: null | Date
}

type Props = {
  name: string
  type: string
  color: string
}
export const CurrentlyRunningColumn: React.FC<Props> = ({
  color,
  name,
  type,
}) => {
  const { isPending, data, isError, error } = useQuery({
    queryKey: ['superpowers', 'integrations', 'currentlyRunning', type],
    queryFn: () => request.get<Item[]>(`importer/${type}/queue`),
    refetchInterval: 1000,
  })
  if (isPending) return <div />
  if (isError) return <Text>{error.message}</Text>

  const running = data.filter((d) => d.isRunning).toReversed()

  return (
    <div>
      <Heading pb="2" size="2xl" color="GrayText">
        {name}
      </Heading>
      <>
        {running.length > 0 && (
          <>
            <Text
              color="GrayText"
              textAlign="center"
              mb="2"
              fontSize="lg"
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              Running
            </Text>
            {running.map((d) => (
              <ItemCard {...d} key={d.integrationKey} color={color} />
            ))}
          </>
        )}
        <Text
          color="GrayText"
          textAlign="center"
          mb="2"
          fontSize="lg"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          Queue
        </Text>
        {data
          .filter((d) => !d.isRunning)
          .toReversed()
          .map((d) => (
            <ItemCard {...d} key={d.integrationKey} color={color} />
          ))}
      </>
    </div>
  )
}
